<template>
    <div>
        <div>
            <breadcrumb
                name="Category"
                title="Category Details"
                subtitle="Edit Accessory"
                @onTitleClicked="goBack"
            />
        </div>
        <div class="body" v-if="!loading">
            <vs-row style="margin-left: 20px; margin-right: 20px;">

                <vs-col vs-lg="6" vs-md="12">
                    <vs-input
                        label="Title English"
                        v-model="title_en"
                        size="medium"
                        class="input"
                        danger-text="This field is mandatory"
                    />
                </vs-col>
                <vs-col vs-lg="6" vs-md="12">
                    <vs-input
                        label="Title Arabic"
                        v-model="title_ar"
                        size="medium"
                        class="input"
                        danger-text="This field is mandatory"
                    />
                </vs-col>
            </vs-row>

            <vs-row>
                <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="6">
                    <text-area
                        label="English Details"
                        v-model="text_en"
                        rows="12"
                        :name="`text_en`"
                    />
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="6">
                    <text-area
                        label="Arabic Details"
                        v-model="text_ar"
                        rows="12"
                        :name="`text_ar`"
                    />
                </vs-col>

                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    class="pa-4"
                    vs-align="center"
                    vs-w="6"
                    style="margin-left: -20px; margin-top: -20px;">
                    <upload-file
                        :limit="1"
                        :file-src="image"
                        text="Choose Image"
                        :show-upload-button="false"
                        @getFile="image = $event"
                    />
                </vs-col>
            </vs-row>

            <div class="optionBtns">
                <vs-button
                    icon-pack="feather"
                    icon="icon-save"
                    :disabled="!invalidAccessory"
                    @click="confirmSaveAccessory"
                >Save</vs-button>
                <vs-button type="border" class="ml-5 mr-10" color="primary" @click="goBack">Cancel</vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import ChooseModel from "../../../../../components/custom/choose-model";
import Breadcrumb from "../../../../../components/general/breadcrumb";
import TextArea from "../../../../../components/form/text-area";
import UploadFile from "../../../../../components/uploader/upload-file";

export default {
    name: "edit-accessory",
    components: { TextArea, Breadcrumb, ChooseModel, UploadFile },
    data() {
        return {
            category_id: this.$route.params.id,
            accesssory_id: this.$route.params.accessoryId,
            categories: [],
            title_en: "",
            title_ar: "",
            text_en: "",
            text_ar: "",
            image: "",
            loading: true
        };
    },
    methods: {
        confirmSaveAccessory() {
            this.$vs.dialog({
                type: "confirm",
                color: "primary",
                title: `Confirm`,
                text: "You're going to save this Accessory!",
                accept: () => this.saveAccessory()
            });
        },
        saveAccessory() {
            this.$vs.loading();

            let formData = new FormData();

            formData.append("category_id", this.category_id);
            formData.append("title_en", this.title_en);
            formData.append("title_ar", this.title_ar);
            formData.append("text_en", this.text_en);
            formData.append("text_ar", this.text_ar);
            formData.append("image", this.image);

            this.$httpCars
                .post("categories/accessories/" + this.accesssory_id, formData)
                .then(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Success",
                        color: "success",
                        text: "Accessory is edited successfully!"
                    });
                    this.$router.push(`/categories/show/${this.category_id}`);
                })
                .catch(() => {
                    //this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        color: "danger",
                        text: "Couldn't Save Accessory!"
                    });
                });
        },
        goBack() {
            this.$router.push(`/categories/show/${this.category_id}`);
        },
      getCategoryById() {
        this.$vs.loading();
        this.$httpCars
          .get(`categories/${this.category_id}`)
          .then(r => {
            let item = r.data.data;
            for (let i = 0; i < item.accessoreis.length; i++) {
              if (item.accessoreis[i].id + "" === this.accesssory_id + "") {
                this.title_en = item.accessoreis[i].title_en;
                this.title_ar = item.accessoreis[i].title_ar;
                this.text_en = item.accessoreis[i].text_en;
                this.text_ar = item.accessoreis[i].text_ar;
                this.image = item.accessoreis[i].image;
                this.loading = false;
                break;
              }
            }
            this.$vs.loading.close();
          })
          .catch(() => {
            //console.log({getCategoryById_error: e});
          });
      }
    },
    computed: {
        invalidAccessory() {
            return (
                this.category_id &&
                this.title_ar &&
                this.title_en &&
                this.text_ar &&
                this.text_en &&
                this.image
            );
        }
    },
    watch: {},
    mounted() {
        this.getCategoryById();
    }
};
</script>

<style scoped lang="scss">
.clr {
    clear: both;
}

.body {
    background-color: #fff;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;

    .drop-down {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    label {
        font-size: 1rem;
        padding: 5px;
    }

    select {
        padding: 10px;
        border-radius: 5px;
        width: 90%;
        background-color: #fff;
        border: 1px solid #00000030;
    }

    .inputs {
        margin: 20px 0 20px 0;
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
    }
}

.input {
    width: 90%;
    margin-top: 30px;
}

.switch {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
}

.checkboxes {
    margin: 10px;

    .checkbox {
        margin: 5px;
    }
}

.optionInput {
    margin-top: 30px;
    width: 50%;
    display: block;
    padding: 10px;
    border-radius: 5px;
    color: #00000090;
    margin-left: 10px;
}

h2 {
    margin: 30px;
}

.optionBtns {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

h4 {
    padding: 20px;
}

.subtitle {
    margin-top: 50px;
    color: #ccc;
}

.input {
    width: 90%;
    margin-top: 10px;
}
</style>
